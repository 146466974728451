
import { CustomerService } from "../../service/customer-service";
const customerService = new CustomerService()
import {mapGetters} from 'vuex';
import { http } from "@/common/services/web-backend.js";
// import MapboxDraw from "@mapbox/mapbox-gl-draw";
// import * as turf from "@turf/turf";

export const customersMixin = {
  data() {
    return {
        mapOptions: {
            accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
            defaultZoom: 7,
            defaultCoordinates: [-70.7260555, 19.0781721],
            defaultMarker: [-70.7260555, 19.0781721],
        },
        data: [],
        showBy: null,
        searchCustomer: '',
        searchByCode: '',
        customers: [],
        loading: false,
        showEntity: false,
        entity: {},
        showCustomersList: true,
        mapKey: 0,
        style: 0,
        images: [],
        unclusteredCustomers: [],
        customerZone: {},
        searchResult: [],
    }
},
computed:{
    ...mapGetters({
        toggleMenu: "layout/toggleMenu"
    }),
    clusters() {
    return {
            id: 'clusters',
            type: 'circle',
            source: 'customers',
            'layout': {
              // Make the layer visible by default.
                'visibility': 'none'
            },
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#51bbd6',
                    100,
                    '#f1f075',
                    750,
                    '#f28cb1'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40
                ]
            },
            
        }
    },
    sourceCustomers() {
        return {
            type: 'geojson',
            data: {
                type: "FeatureCollection",
                features: (this.isRoutes ?? null) && this.filters.zones.length > 0 
                            ? this.customersWithFilters 
                            : this.getClusters
                // this.searchResult.length > 0 ? this.searchResult : this.filters.showAllCustomer ? this.customers 
                // : this.filters.showCustomersWithZone ? this.customerZone.customersWithZone 
                // : this.filters.showCustomersWithoutZone ? this.customerZone.customersWithoutZone : this.customers
            },
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        }
    },
    sourceCustomersRoutes() {
        return {
            type: 'geojson',
            data: {
                type: "FeatureCollection",
                features: (this.isRoutes ?? null) && this.filters.zones.length > 0 
                            ? this.customersWithFilters 
                            : this.customers
                // this.searchResult.length > 0 ? this.searchResult : this.filters.showAllCustomer ? this.customers 
                // : this.filters.showCustomersWithZone ? this.customerZone.customersWithZone 
                // : this.filters.showCustomersWithoutZone ? this.customerZone.customersWithoutZone : this.customers
            },
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        }
    },
    clusterCount() {
        return {
            id: 'cluster-count',
            type: 'symbol',
            source: 'customers',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            }
        }
    },
    unclustered() {
        return {
            id: 'unclustered-point',
            type: 'circle',
            source: 'customers',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#fff',
                'circle-radius': 0,
                'circle-stroke-width': 0,
                'circle-stroke-color': '#fff'
            }
        }
    },
    options(){
        let status = [
            {value: null, text:'Todos los estados'},
            {value: 'register', text:'Registrados'},
            {value: 'customers', text:'Clientes'}
        ]
        return status
    },
    fields(){
        return [
            { label: this.$t("ecommerce.name"), key: "name", sortable: true },
            { label: this.$t("ecommerce.code"), key: "code", sortable: true },
            { label: this.$t("zones.verified"), key: "verified", class: "text-center"},
          // {label: this.$t('ecommerce.phone'), key: 'phone', sortable: true},
          // {label: this.$t('ecommerce.actions'), key: 'actions', sortable: false},
        ];
    },
    getClusters(){
      let result = []
      if(this.searchCustomer != '' && this.searchCustomer != null){
        console.log(this.searchCustomer, 'here');
        result = this.searchResult
      }else if(this.filters.showCustomersWithZone && this.filters.verified && !this.filters.showCustomersWithoutZone && !this.filters.notVerified){
        let filter = this.customerZone.customersWithZone.filter(item=> item.properties.verified == true)
        result = filter
      }else if(!this.filters.showCustomersWithZone && this.filters.verified && this.filters.showCustomersWithoutZone && !this.filters.notVerified){
        let filter = this.customerZone.customersWithoutZone.filter(item=> item.properties.verified == true)
        result = filter
      }else if(this.filters.showCustomersWithZone && !this.filters.verified && !this.filters.showCustomersWithoutZone && this.filters.notVerified){
        let filter = this.customerZone.customersWithZone.filter(item=> item.properties.verified == false)
        result = filter
      }else if(!this.filters.showCustomersWithZone && !this.filters.verified && this.filters.showCustomersWithoutZone && this.filters.notVerified){
        let filter = this.customerZone.customersWithoutZone.filter(item=> item.properties.verified == false)
        result = filter
      }else if(this.filters.showCustomersWithZone && !this.filters.verified && !this.filters.showCustomersWithoutZone && !this.filters.notVerified){
        result = this.customerZone.customersWithZone
      }else if(!this.filters.showCustomersWithZone && !this.filters.verified && this.filters.showCustomersWithoutZone && !this.filters.notVerified){
        result = this.customerZone.customersWithoutZone
      }else if(!this.filters.showCustomersWithZone && this.filters.verified && !this.filters.showCustomersWithoutZone && !this.filters.notVerified
        || this.filters.showCustomersWithZone && this.filters.verified && this.filters.showCustomersWithoutZone && !this.filters.notVerified){
        let verified = this.data.filter(item=> item.properties.verified == true)
        result = verified
      }else if(!this.filters.showCustomersWithZone && !this.filters.verified && !this.filters.showCustomersWithoutZone && this.filters.notVerified
        || this.filters.showCustomersWithZone && !this.filters.verified && this.filters.showCustomersWithoutZone && this.filters.notVerified){
        let notVerified = this.data.filter(item=> item.properties.verified == false)
        result = notVerified
      }
      else{
        result = this.customers
      }
      return result
    }
} , 
methods:{
    clickZoom(e) {
        var features = this.$refs.map.map.queryRenderedFeatures(e.mapboxEvent.point, {
            layers: ['clusters']
        });
        var clusterId = features[0].properties.cluster_id;
        this.$refs.map.map.getSource('customers').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
                if (err) return;

                this.$refs.map.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
                });
            }
        );
    },
    putPointer() {
        this.$refs.map.map.getCanvas().style.cursor = 'pointer';
    },
    removePointer() {
        this.$refs.map.map.getCanvas().style.cursor = '';
    },
    async getImages(code){
        try {
            const response = await customerService.getImages(code)
            this.images = response.data
            this.showEntity = true
        } catch (error) {
            console.log(error);
        }
    },
    async fetchPoints(type = 'registrados') {
        switch (type) {
            case "registrados":
            await this.getPointsFromERP()
            // console.log('registrados');
            break;
            case "solicitudes":
            await this.getPointsFromMM()
            break
            default:
            console.error('tipo no encontrado.');
        }
    },
    async getPointCustomers(payload){
        try {
            this.loading = true
            const data = await http.post('addresses/get-addresses-within-limits', payload)
            this.data = data.data.data
            this.customers  = this.data
            this.getCustomerZones()
        } catch (error) {
            this.$swal.fire({ 
                title: this.$t('tracking.unexpected_error'), 
                text: error.response.data.message,
                icon: "error",
            })
            console.log(error);
        } finally {
            this.loading = false
        }
    },
    async getPointsFromERP(){
        try {
            this.loading = true
            const data = await http.get(`${process.env.VUE_APP_BACKEND_ERP}geography/map`);
            this.data = data.data.data;
            this.customers = this.data;
        } catch (e) {
            console.log(e)
        }finally{
            this.loading = false
        }
    },
    async selectAddress(address){
        if(address[0]){
            await this.getImages(address[0].properties.code)
            this.showEntity = true
            this.$refs.map.map.easeTo({
                center: address[0].geometry.coordinates,
                zoom: 14
            });
        }
        this.$refs.customersTable.clearSelected()
    },
    changeStyle(){
        this.style = this.style + 1;
        if(this.style == this.mapStyle.length) {
            this.style = 0;
        }
        this.mapKey += 1
    },
    getMarkers(){
        this.unclusteredCustomers = []
        if(this.filters.showAllCustomer || this.filters.showCustomersWithZone 
            || this.filters.showCustomersWithoutZone || this.filters.verified 
            || this.filters.notVerified || this.searchCustomer || this.filters?.zones?.length > 0){
            const clusters = this.$refs.map.map.queryRenderedFeatures({ layers: ["unclustered-point"] });
            clusters.forEach(item => {
                let index = this.unclusteredCustomers.findIndex(index => index.properties.address_id == item.properties.address_id)
                if(index == -1){
                this.unclusteredCustomers.push(item)
                }
            });
            // this.unclusteredCustomers = clusters
        }
    },
},
watch:{
    searchCustomer(val){
        if(!isNaN(val) && val != ''){
            let result = this.data.filter(client => client.properties.code.toLowerCase().includes(val))
            this.searchResult = result
            this.$refs.map.map.setLayoutProperty( 'clusters', 'visibility', 'visible');
        }else if(val){     
            let result = this.data.filter(client => (client.properties.first_name.toLowerCase() + client.properties.last_name.toLowerCase() 
            + client.properties?.company_name?.toLowerCase()).includes(val))
            this.searchResult = result
            this.$refs.map.map.setLayoutProperty( 'clusters', 'visibility', 'visible');
        }else{
            this.searchResult = []
            this.$refs.map.map.setLayoutProperty('clusters', 'visibility', 'none');
            this.unclusteredCustomers = [] 
        }
        setTimeout(() => {this.getMarkers()}, 100);
    },
    toggleMenu(){
        this.mapKey += 1
    }
}
}
