<template>
        <main-modal :title="$t('zones.route') + ': ' + singleRoute.name" @closeModal="$emit('closeModal')">
        <el-row class="my-4" :gutter="40">
            <el-col :xl="4" :lg="6" :md="8">
                <div class="d-flex flex-column">
                    <label>{{ $t('zones.assign_to') }}:</label>
                    <div class="d-flex">
                        <b-form-input v-model="$v.search.$model" @keyup.enter="filterEmployeesAndSelect()" :class="{'invalid' : $v.search.$error}" placeholder="code" style="border-top-right-radius: 0;border-bottom-right-radius: 0; width: 135px;"></b-form-input>
                        <b-button class="py-0 px-2" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px" @click="showListEmployee = true">
                            <eva-icon name="search" width="20"></eva-icon>
                        </b-button>
                    </div>
                    <span v-if="notFoundEmployee" style="margin-left: 4px; color: crimson;">{{$t('zones.not_found_employee') | textFormat}}</span>
                </div>
            </el-col>
            <el-col :xl="4" :lg="6" :md="8">
                <div class="d-flex flex-column">
                    <label>{{$t('zones.name') }}:</label>
                    <div class="d-flex">
                        <el-input style="border-top-right-radius: 0;border-bottom-right-radius: 0;" :placeholder="`${selectEmployee}`" :disabled="true">
                        </el-input>
                    </div>
                </div>
            </el-col>
            <el-col :xl="4" :lg="6" :md="8">
                <div class="d-flex flex-column">
                    <label>{{ $t('zones.assign_locality') }}:</label>
                    <div class="d-flex">
                        <multiselect v-model="$v.point_of_sale.$model" :options="stores" label="description" track-by="id"/>
                    </div>
                </div>
            </el-col>
            <el-col :xl="4" :lg="6" :md="8">
                <div class="d-flex flex-column">
                    <label>{{ $t('zones.task_start_date') }}:</label>
                    <div class="d-flex">
                        <el-date-picker :picker-options="pickerOptions" v-model="taskDate" type="date" value-format="yyyy-MM-dd" placeholder="Pick a day">
                        </el-date-picker>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-container style="gap:130px; margin-top: 80px;">
                <el-container class="d-flex flex-column">
                    <h5>{{ $t('zones.customers') }}</h5>
                    <el-input style="margin: 6px; margin-left: -1px;" placeholder="Search" v-model="searchAddress" clearable>
                    </el-input>
                        <el-table size="mini" v-loading="loading" ref="multipleTable" :data="searchAddress ? result : customers" style="width: 100%;" stripe max-height="400" @selection-change="handleSelectionChange" header-cell-class-name="text-white" :header-cell-style="{'background-color' : '#308EEC'}">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column label="id" property="properties.address_id" width="60">
                            </el-table-column>
                            <el-table-column class="lowerCase-style" property="properties.address_name" :label="$t('zones.name')" width="220">
                            </el-table-column>
                            <!-- <el-table-column  width="230" property="properties.reference" label="Referencia" show-overflow-tooltip>
                            </el-table-column> -->
                            <el-table-column width="100" :label="$t('zones.actions')" align="center" fixed="right">
                                    <template slot-scope="scope">
                                        <el-tooltip :content="$t('zones.show')" placement="top">
                                            <el-button size="mini" type="primary" @click="handleShowCustomers(scope.$index, scope.row)" circle icon="el-icon-view"/>
                                        </el-tooltip>
                                    </template>
                            </el-table-column>
                        </el-table>
                </el-container>
                <div style="align-self: center;">
                    <el-button style="width: 110px;" :disabled="selectedEmployee.length == 0" type="primary" @click="toggleSelection()">=></el-button>
                </div>
                <template>
                    <el-container class="d-flex flex-column">
                        <h5>{{ $t('zones.employees') }}</h5>
                        <el-input style="margin: 6px; margin-left: -1px;" placeholder="Search" v-model="searchEmployee" clearable>
                        </el-input>
                        <!-- <el-card size="mini" style="max-width: 655px;"> -->
                            <el-table  size="mini" ref="multipleTable" :data="searchEmployee ? resultEmployee : employeeTable" stripe style="width: 100%;" max-height="400" @selection-change="handleSelectionChangeEmployee" header-cell-class-name="text-white" :header-cell-style="{'background-color' : '#308EEC'}">
                                <el-table-column label="id" property="employee_code" width="60">
                                </el-table-column>
                                <el-table-column property="name" :label="$t('zones.name')" width="170">
                                </el-table-column>
                                <el-table-column property="quantity" :label="$t('zones.quantity')" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column width="149" :label="$t('zones.actions')" align="center" fixed="right">
                                    <template slot-scope="scope">
                                        <el-tooltip :content="$t('zones.show')" placement="top">
                                            <el-popover placement="bottom" width="400" trigger="click">
                                                <div style="text-align: right;">
                                                    <el-table size="mini" ref="multipleTable" :data="selectedCustomers" style="width: 100%;" stripe max-height="300">
                                                        <el-table-column label="id" property="properties.address_id" width="120" heigth="100">
                                                        </el-table-column>
                                                        <el-table-column class="lowerCase-style" property="properties.address_name" :label="$t('zones.name')" width="120">
                                                        </el-table-column>
                                                        <el-table-column  width="230" property="properties.reference" :label="$t('zones.reference')" show-overflow-tooltip>
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                                <el-button style="margin-right: 3px"  size="mini" type="primary" @click="handleShow(scope.row.addresses_customers)" circle icon="el-icon-view" slot="reference"/>
                                            </el-popover>
                                        </el-tooltip>
                                        <!-- <el-button size="mini" type="primary" @click="handleShow(scope.$index, scope.row)" circle icon="el-icon-view"/> -->
                                        <el-tooltip :content="$t('zones.delete')" placement="top">
                                            <el-button size="mini" type="danger" @click="handleDelete(scope.row.employee_code)" circle icon="el-icon-delete"/>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>
                        <!-- </el-card> -->
                    </el-container>
                </template>
        </el-container>
        <div class="position-button">
            <b-col lg="2" class="d-flex justify-content-between align-items-center">
                <el-button size="small"  :disabled="this.point_of_sale == null"  @click="saveAddress()" style="width: 150px; height: 33px;" :loading="loading" type="primary">{{ $t('zones.assign_customers') }}</el-button>
                <el-button size="small"  @click="cancelAddress()" style="width: 150px; height: 33px;"  type="danger">
                    {{ $t('zones.cancel') }}
                </el-button>
            </b-col>
        </div>
        <info-customers :viewCustomers="viewCustomers" @closeModal="showInfoCustomer = false" v-if="showInfoCustomer"></info-customers>
    </main-modal>
</template>

<script>
import MainModal from '../../../common/components/modals/MainModal.vue';
import { http } from '../../../common/services/web-backend';
import {required} from 'vuelidate/lib/validators'
import InfoCustomers from './InfoCustomers.vue';
export default {

    components: { 
        MainModal,
        InfoCustomers 
        // Popup
    },
    name: 'SingleRouteModal',
    props:{
        singleRoute:{
            type: Object
        },
        stores:{
            type: Array
        }
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },
            visible: false,
            search: '',
            showInfoCustomer: false,
            taskDate: '',
            point_of_sale: null,
            searchAddress: '',
            searchEmployee: '',
            employees: [],
            loading: false,
            showListEmployee: false,
            value: [],
            multipleSelection: [],
            multipleSelectionEmployee: [],
            result: [],
            selectedEmployee: [],
            customers: [],
            selectEmployee: [],
            listEmployees: [],
            employeeTable: [],
            resultEmployee: [],
            selectedCustomers: [],
            viewCustomers: {},
            notFoundEmployee: false,
        };
    },
    validations: {
        search: {
            required
        },

        point_of_sale: {
            required
        }
        // type: {
        //     required
        // },
    },
    watch:{
        'singleRoute'(){
            // console.log(this.singleRoute);
            this.getSingleRoutesFilters(this.singleRoute.filters)
            this.getEmployee()
        },
        // 'point_of_sale'(){
        //     console.log(this.point_of_sale);
        // },
        'search'(){
            if(this.search == ''){
                this.selectEmployee = ''
            }
        },
        // 'stores'(){
        //     console.log(this.stores);
        // },
        searchEmployee(val){
            const result = this.employeeTable.filter(data => data.name.toLowerCase().includes(val.toLowerCase()))
            this.resultEmployee = result
        },
        searchAddress(val){
            const result = this.customers.filter(data => data.properties.address_name.toLowerCase().includes(val.toLowerCase()))
            this.result = result
        },
    },
    methods:{
        handleSelectionChange(val) {
            this.multipleSelection = val;
            // console.log(this.multipleSelection);
        },
        toggleSelection(){
            const validationEmployee = this.employeeTable.filter(data => data.employee_code === this.selectedEmployee[0]?.employee_code)
            // console.log(validationEmployee);
            if(validationEmployee.length == 0){
                this.selectedEmployee.forEach(select => {
                    this.employeeTable.push({
                        employee_code: select.employee_code,
                        name: select.name,
                        quantity: this.multipleSelection.length,
                        addresses_customers: this.multipleSelection,
                    })
                })
                // Este método elimina duplicados del array "items"
                const result = this.customers.filter(item => !this.multipleSelection.includes(item));

                this.customers = result
                this.selectedEmployee = []
                this.search = ''
                this.selectEmployee = ''
                return
            } else {
                validationEmployee[0].addresses_customers.push(...this.multipleSelection)
                validationEmployee[0].quantity = validationEmployee[0].addresses_customers.length

                const result = this.customers.filter(item => !this.multipleSelection.includes(item));
                this.customers = result
                this.selectedEmployee = []
                this.search = ''
                this.selectEmployee = ''
                return
            }
        },
        handleSelectionChangeEmployee(val){
            this.multipleSelectionEmployee = val
        },
        async getSingleRoutesFilters(payload){
            try {
                this.loading = true
                const response = await http.post(`addresses/get-addresses-within-limits`, payload)
                this.customers = response.data.data;
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('zones.error_occurred'),
                    text: error.response ? error.response.data.message : error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filterEmployeesAndSelect(){
            this.selectedEmployee = []
            const filterEmployeee = this.listEmployees.filter(data => data.id === this.search)

            if(filterEmployeee.length == 0){
                return this.notFoundEmployee = true
            }

            const validationEmployee = this.employeeTable.filter(data => data.employee_code === filterEmployeee[0]?.id)
            this.notFoundEmployee = false
            if(validationEmployee.length == 0){
                // console.log(validationEmployee);
                filterEmployeee.forEach(employee => {
                    this.selectedEmployee.push({
                        employee_code: employee.id,
                        name: employee.name,
                        addresses_customers: [],
                    })
                })
                this.selectEmployee = this.selectedEmployee[0]?.name
                return
            } else {
                this.selectEmployee = validationEmployee[0]?.name
                this.selectedEmployee = validationEmployee
                return
            }
        },
        async getEmployee(){
            try {
                const {data} = await http.get('/employee?limit=500');

                data.data.data.forEach(employee => {
                    this.listEmployees.push({
                        id: employee.entity.code,
                        name: employee.entity.first_name.toLowerCase(),
                        active: employee.entity.activated_employee
                    })
                });
            } catch (error) {
                console.log(error);
            }
        },
        handleShow(id){
            this.selectedCustomers = id
        },
        async saveAddress(){
            this.loading = true
            this.employeeTable.forEach((data) => {
                data.addresses_customers.forEach((customers) => {
                    customers.id = data.employee_code
                });
            });

            const allAddress = []
            this.employeeTable.forEach((data) => {
                allAddress.push(...data.addresses_customers);
            });

            const addresses = []

            allAddress.forEach((address) => {
                addresses.push({
                    employee_code: address.id,
                    id: address.properties.address_id,
                    create_task: true
                })
            })
            

            const addressesNotCreateTaks = this.customers
            const listNoCreateTaks = []
            
            addressesNotCreateTaks.forEach((data) => {
                listNoCreateTaks.push({
                    id: data.properties.address_id,
                    create_task: false
                })
            })

            addresses.push(...listNoCreateTaks)

            const data = { 
                route_setting_id: this.singleRoute.id,
                task_creation_date: this.taskDate,
                point_of_sale_id: this.point_of_sale.id,
                addresses: addresses
            }

            try {
                const response = await http.post(`routes`, data)
                console.log(response);
                this.$swal.fire({ 
                    title: this.$t('zones.saved_successfully'), text: this.$t('zones.saved_successfully'), 
                    icon: "success",
                })
                this.$emit('closeModal')
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('zones.error_occurred'),
                    text: error.response ? error.response.data.message : error.message,
                })
                // this.$emit('closeModal')
            } finally {
                this.loading = false
            }
            console.log(data);
        },
        cancelAddress(){
            this.$emit('closeModal')
        },
        handleShowCustomers(index, id){
            // console.log(index, id);
            this.viewCustomers = id
            this.showInfoCustomer = true
        },
        handleDelete(id){
                this.loadingEmployees = true
                const employees =  this.employeeTable
                const result = employees.filter(data => data.employee_code == id)
                const data = []
                result.forEach(addresses => {
                    data.push({
                        addresses_customers: addresses.addresses_customers
                    })
                })
                const resultData = data[0].addresses_customers

                const mergedResult = []

                resultData.forEach(data => {
                    mergedResult.push({
                        geometry: data.geometry,
                        properties: data.properties,
                        type: data.type
                    })
                })

                mergedResult.forEach((object) => {
                    this.customers.push(object);
                })

                const removeEmployee = this.employeeTable.filter(object => object.employee_code !== id)
                this.employeeTable = removeEmployee

        }
    },
}
</script>

<style>
.position-button{
    margin-top: 10px;
    text-align: -webkit-right;
}
.multiselect__tags {
    height: 40px !important; 
}

.form-control {
    height: 40px !important; 
}

.multiselect__single {
    top: 3px;
}

.cell{
    text-transform: lowercase !important;
}
</style>