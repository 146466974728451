<template>
    <div class="d-flex flex-column">
        <div>
            <label :for="id" class="mb-1 cursor-pointer">{{ $t(title) }}</label>
            <el-popover placement="bottom" width="200" trigger="click">
                <div>
                    <el-select v-model="filterType" placeholder="Select" size="small">
                        <el-option v-for="item in types" :key="item.id" :label="item.text" :value="item.id"/>
                    </el-select>
                </div>
                <el-button type="text" class="ms-2 p-0" slot="reference">
                    <i :class="[filterType == 1 ? 'el-icon-folder' : 'el-icon-folder-delete']" :style="{color:filterType == 2 ? '#F56C6C' : null}"></i>
                </el-button>
            </el-popover>
        </div>
        <el-select v-model="value" multiple collapse-tags placeholder="Select" size="small" :id="id" filterable>
            <el-option v-for="item in options" :key="item[keyValue]" :label="item.label || item.name" :value="item[keyValue] || item"/>
        </el-select>
    </div>
</template>

<script>

    export default {
        name: "AppSelection",
        data(){
            return {
                types: [
                    {id: 1, text: 'is'},
                    {id: 2, text: 'is not'},
                ],
                filterType: 1,
                value: []
            }
        },
        props:{
            title: {
                type: String
            },
            options:{
                type: Array
            },
            default:{
                type: [Object, Array]
            },
            id: {
                type: String
            },
            keyValue:{
                type: String
            },
            reload: {
                type: Array
            }
        },
        watch:{
            value(){
                this.setValue()
            },
            filterType(){
                this.setValue()
            },
            reload(){
                this.value = this.reload
            },
            // default(val){
            //     this.value = val
            // }
        },
        methods:{
            setValue(){
                if(this.filterType == 2 && this.value.length > 0){
                    const intersection = this.options.filter(el => !this.value.includes(el[this.keyValue])).map(o => o[this.keyValue]);
                    this.$emit('change', intersection)
                }else{
                    this.$emit('change', this.value)
                }
            }
        },
        created(){
            if(this.default)
            this.value = this.default
        }
    }
</script>

<style scoped>
    .filter-type{
        z-index: 1;
        width: 100px;
        right: 35px;
    }
</style>